<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Cashier</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <base-title></base-title>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header d-flex justify-content-between">
              <h4>{{ name }} {{ username }}</h4>
              <h4 v-if="data?.order_detail[0]">
                {{ dateFormat(data?.order_detail[0]?.created_at) }}
              </h4>
            </div>
            <form @submit.prevent="cashierInsert">
              <div class="card-body">
                <detail-table :data="data"></detail-table>
                <div class="row">
                  <div class="col-md-4">
                    <ladies-list :data="data"></ladies-list>
                  </div>
                  <div class="col-md-8">
                    <div class="form-row">
                      <div class="col-6">
                        <label>Payment Method</label>
                        <select
                          required
                          class="form-control"
                          v-model="form.payment_method_id"
                        >
                          <option selected value="">
                            Select Payment Method
                          </option>
                          <option
                            :value="payment_type.id"
                            v-for="payment_type in payment_types"
                            :key="payment_type.id"
                          >
                            {{ payment_type.name }}
                          </option>
                        </select>
                        <small
                          class="text-danger"
                          v-if="errors.payment_method_id"
                          >{{ errors.payment_method_id[0] }}
                        </small>
                      </div>
                      <div class="col-6">
                        <label>Status</label>
                        <select disabled class="form-control">
                          <option selected value="PAID">Lunas</option>
                          <!-- <option value="">Select Status</option>
                          <option
                            :value="index"
                            v-for="(s, index) in status"
                            :key="index"
                          >
                            {{ s }}
                          </option> -->
                        </select>
                        <small class="text-danger" v-if="errors.status"
                          >{{ errors.status[0] }}
                        </small>
                      </div>
                      <div
                        v-if="form.status == 'EXPIRED' || form.status == 'VOID'"
                      >
                        <div class="col-12">
                          <StreamBarcodeReader
                            @decode="onDecodeStatus"
                            @loaded="onLoaded"
                          ></StreamBarcodeReader>
                        </div>
                        <div class="form-row">
                          <div class="col">
                            <label>User_ID</label>
                            <input
                              type="text"
                              readonly
                              class="form-control"
                              v-model="form_update_status.IDuser"
                            />
                          </div>
                          <!-- <div class="col">
                            <label>Name User</label>
                            <input
                              type="text"
                              readonly
                              class="form-control"
                              v-model="form_update_status.nameUser"
                            />
                          </div> -->
                          <div class="col">
                            <label>Nickname</label>
                            <input
                              type="text"
                              readonly
                              class="form-control"
                              v-model="form_update_status.nickname"
                            />
                          </div>
                        </div>
                        <div class="col-12" v-if="form.status == 'VOID'">
                          <label>Product</label>
                          <v2-select
                            v-model="form_update_status.order_details"
                            multiple
                            :options="data.order_detail"
                            :reduce="(order_detail) => order_detail.id"
                            label="name_product"
                          >
                          </v2-select>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label>Note</label>
                          <textarea
                            name=""
                            class="form-control"
                            v-model="form.note"
                            style="height: 100px"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4"></div>
                  <div class="col-md-8 text-left">
                    <div class="row">
                      <div class="col">
                        <div class="invoice-detail-item">
                          <div class="invoice-detail-name">Subtotal</div>
                          <h5 class="font-weight-bold">
                            Rp. {{ formatPrice(data.total_price) }}
                          </h5>
                        </div>
                      </div>
                      <div class="col">
                        <div class="invoice-detail-item">
                          <div class="invoice-detail-name">Change</div>
                          <h5 class="font-weight-bold">
                            Rp.
                            {{ formatPrice(totalExchange) }}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="invoice-detail-item">
                          <div class="invoice-detail-name">Discount Type</div>
                          <select
                            v-model="form.discount_type"
                            class="form-control"
                          >
                            <option value="">Pilih Tipe Diskon</option>
                            <option
                              v-for="(discount_type, index) in discount_types"
                              :key="index"
                              :value="index"
                            >
                              {{ discount_type }}
                            </option>
                          </select>
                          <div
                            v-if="
                              form.discount_type == 'APPLICATION' &&
                              hide == false
                            "
                            class="my-3"
                          >
                            <StreamBarcodeReader
                              v-if="!form_application.is_scan"
                              @decode="onDecodeApplication"
                              @loaded="onLoaded"
                            ></StreamBarcodeReader>
                          </div>
                          <div
                            v-if="
                              form.discount_type == 'PERCENTAGE' ||
                              form.discount_type == 'REBATE'
                            "
                          >
                            <StreamBarcodeReader
                              @decode="onDecode1"
                              @loaded="onLoaded"
                            ></StreamBarcodeReader>
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="invoice-detail-item">
                          <div class="invoice-detail-name">Payout</div>
                          <money
                            v-model="form.total_payout"
                            class="form-control"
                            @change="moneyChange"
                            :disabled="
                              form.status == 'VOID' || form.status == 'EXPIRED'
                            "
                          ></money>
                        </div>
                        <div
                          class="my-3"
                          v-if="form.discount_type == 'APPLICATION'"
                        >
                          <div class="form-group">
                            <label>Nickname</label>
                            <input
                              type="text"
                              class="form-control"
                              disabled
                              v-model="total_point.nickname"
                            />
                          </div>
                          <div class="form-group">
                            <label>Loyalty</label>
                            <h3>Rp. {{ formatPrice(total_point.royalty) }}</h3>
                          </div>
                          <div class="form-group">
                            <label>Point</label>
                            <h3>Rp. {{ formatPrice(total_point.point) }}</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="invoice-detail-item"
                      v-if="form.discount_type != ''"
                    >
                      <div v-if="form.discount_type != 'APPLICATION'">
                        <div class="row">
                          <div class="col">
                            <div class="invoice-detail-name">ID User</div>
                            <input
                              type="text"
                              readonly
                              class="form-control"
                              v-model="IDuser"
                            />
                          </div>
                          <!-- <div class="col">
                            <div class="invoice-detail-name">Name</div>
                            <input
                              type="text"
                              readonly
                              class="form-control"
                              v-model="NameUser"
                            />
                          </div> -->
                          <div class="col">
                            <div class="invoice-detail-name">Nickname</div>
                            <input
                              type="text"
                              readonly
                              class="form-control"
                              v-model="nickname"
                            />
                          </div>
                        </div>
                        <div class="invoice-detail-name">Discount</div>
                        <input
                          type="number"
                          :disabled="IDuser == ''"
                          class="form-control"
                          v-model.number="form.discount"
                        />
                      </div>
                      <div v-else>
                        <div class="form-group">
                          <label>Diskon Membership</label>
                          <input
                            type="number"
                            class="form-control"
                            readonly
                            v-model.number="total_point.cashback"
                          />
                        </div>
                        <div class="form-group">
                          <label>Diskon Loyalty</label>
                          <input
                            type="number"
                            readonly
                            class="form-control"
                            :max="total_point.royalty"
                            v-model.number="form_application.use_loyalty"
                          />
                        </div>
                        <div class="form-group">
                          <label>Diskon Point</label>
                          <input
                            type="number"
                            readonly
                            class="form-control"
                            :max="total_point.point"
                            v-model.number="form_application.use_point"
                          />
                        </div>
                        <div class="invoice-detail-name">Verification Code</div>
                        <input
                          type="number"
                          class="form-control"
                          v-model="verification_code"
                        />
                      </div>
                    </div>
                    <hr class="mt-2 mb-2" />
                    <div class="invoice-detail-item">
                      <div class="invoice-detail-name">Total</div>
                      <h3 class="font-weight-bold invoice-detail-value-lg">
                        Rp. {{ formatPrice(totalPay) }}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer text-right">
                <button
                  v-if="form.status !== 'PAID'"
                  class="btn btn-primary mr-1"
                  type="submit"
                  :disabled="isSubmitting"
                >
                  Submit
                </button>
                <router-link :to="{ name: 'Cashier' }" class="btn btn-danger"
                  >Back</router-link
                >
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import { apiGet } from '../../services/api';
import { ProfileRepository } from '../../repositories/RepositoryFactory';
import { StreamBarcodeReader } from 'vue-barcode-reader';
import swal from 'sweetalert';
import DetailTable from './components/DetailTable.vue';
import LadiesList from './components/LadiesList.vue';
export default {
  name: 'FormCashier',
  components: { StreamBarcodeReader, DetailTable, LadiesList },
  data() {
    return {
      name: 'Form Cashier',
      data: [],
      isSubmitting: false,
      errors: {},
      edit: false,
      payment_types: [],
      hide: false,
      discount_types: [],
      aplication_user_id: '',
      application_user_status_id: '',
      total_point: [],
      verification_code: '',
      IDuser: '',
      status: [],
      NameUser: '',
      nickname: '',
      username: '',
      ladies: [],
      form_update_status: {
        IDuser: '',
        nameUser: '',
        nickname: '',
        order_details: [],
      },
      form_application: {
        use_loyalty: 0,
        use_point: 0,
        is_scan: false,
      },
      form: {
        status: '',
        payment_method_id: '',
        discount: 0,
        total_payout: 0,
        note: '',
        discount_type: null,
        status_user_acc: '',
      },
    };
  },
  mounted() {
    this.getData();
    this.getPayment();
    this.getStatus();
    this.getLadies();
    ProfileRepository.get().then(({ data }) => {
      this.username = data.data.username;
    });
  },
  methods: {
    dateFormat(date) {
      return moment(date).format('LLL');
    },
    getLadies() {
      apiGet('ladies').then((res) => (this.ladies = res.data.data));
    },
    onDecodeApplication(result) {
      this.form_application.is_scan = false;
      this.aplication_user_id = result;
      let formData = new FormData();
      formData.set('aplication_user_id', this.aplication_user_id);
      axios
        .post(this.$store.state.api + 'get_member_card', formData, {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then((result) => {
          axios.get(
            this.$store.state.api +
              'verification_code?IDuser=' +
              this.aplication_user_id
          );
          this.$noty.success('We Got Your Member Code!');
          this.total_point = result.data.data;

          if (result.data.data.nickname == '') {
            this.total_point.nickname = this.form.customer_name;
          } else {
            this.total_point.nickname = result.data.data.nickname;
          }

          if (this.totalPay > this.total_point.royalty) {
            this.form_application.use_loyalty = parseInt(
              this.total_point.royalty
            );
          } else {
            this.form_application.use_loyalty = parseInt(this.totalPay);
          }
          if (this.totalPay > this.total_point.point) {
            this.form_application.use_point = parseInt(this.total_point.point);
          } else {
            this.form_application.use_point = parseInt(this.totalPay);
          }
          this.form_application.is_scan = true;
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.isSubmitting = false;
        });
    },
    onDecode1(result) {
      this.$noty.success('We Got User ID!');
      this.IDuser = result;
      let formData = new FormData();
      formData.set('aplication_user_id', this.IDuser);
      axios
        .post(this.$store.state.api + 'get_member_card', formData, {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then((result) => {
          this.$noty.success('We Got Your Member Code!');
          this.NameUser = result.data.data.name;
          if (result.data.data.nickname == '') {
            this.nickname = this.form.customer_name;
          } else {
            this.nickname = result.data.data.nickname;
          }
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.isSubmitting = false;
        });
    },
    onDecodeStatus(result) {
      this.form_update_status.IDuser = result;
      let formData = new FormData();
      formData.set('aplication_user_id', this.form_update_status.IDuser);
      axios
        .post(this.$store.state.api + 'get_member_card', formData, {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then((result) => {
          this.$noty.success('We Got Your Member Code!');
          this.form_update_status.nameUser = result.data.data.name;
          if (result.data.data.nickname == '') {
            this.form_update_status.nickname = this.form.customer_name;
          } else {
            this.form_update_status.nickname = result.data.data.nickname;
          }
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.isSubmitting = false;
        });
    },
    onLoaded(result) {
      console.log(result);
    },
    getData() {
      let id = this.$route.params.id;
      apiGet('cashier/' + id)
        .then((result) => {
          this.data = result.data.data;
          this.form = result.data.data;
          this.form.discount_type = this.form.discount_type || '';
          this.edit = true;
        })
        .catch((err) => {
          console.log(err);
        });
      apiGet('cashier/get_discount_type').then((result) => {
        this.discount_types = result.data;
      });
    },
    getPayment() {
      apiGet('payment_method')
        .then((result) => {
          this.payment_types = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getStatus() {
      let id = this.$route.params.id;
      apiGet('cashier/get_status_list/' + id)
        .then((result) => {
          this.status = result.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    moneyChange() {
      this.change = this.total_payout - this.data.total_price;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    cashierInsert() {
      this.isSubmitting = true;
      let formData = new FormData();
      if (this.form.status == 'VOID' || this.form.status == 'EXPIRED') {
        formData.set(
          'order_detail_id[]',
          this.form_update_status.order_details
        );
        formData.set('IDuser', this.form_update_status.IDuser);
        formData.set('status', 'PAID');
        formData.set('note', this.form.note);
      } else {
        formData.set('payment_method_id', this.form.payment_method_id);
        if (this.form.discount_type != null) {
          formData.set('discount_type', this.form.discount_type);
        } else {
          formData.set('discount_type', '');
        }
        if (this.form.discount_type != 'APPLICATION') {
          formData.set('discount', this.form.discount);
          formData.set('IDuser', this.IDuser);
        } else {
          formData.set('discount', this.totalDiscount);
          formData.set('IDuser', this.total_point.IDuser);
          formData.set('use_loyalty', this.form_application.use_loyalty);
          formData.set('use_point', this.form_application.use_point);
          formData.set('use_member', this.totalMember);
        }
        formData.set('verification_code', this.verification_code);
        formData.set('note', this.form.note);
        formData.set('status', 'PAID');
        formData.set('total_payout', this.form.total_payout);
        formData.set('change', this.totalExchange);
      }

      let id = this.$route.params.id;

      if (this.form.status == 'VOID' || this.form.status == 'EXPIRED') {
        axios
          .post(this.$store.state.api + 'cashier/update/' + id, formData, {
            headers: {
              Authorization: this.$store.state.token,
            },
          })
          .then(() => {
            this.$noty.success('Your cashier has been updated!');
            this.$router.push({ name: 'Cashier' });
          })
          .catch((error) => {
            this.errors = error.response.data;
            this.isSubmitting = false;
          });
      } else if (this.totalExchange >= 0) {
        axios
          .post(this.$store.state.api + 'cashier/update/' + id, formData, {
            headers: {
              Authorization: this.$store.state.token,
            },
          })
          .then((result) => {
            if (result.data.code == 404) {
              swal('Error!', result.data.message, 'error');
              this.isSubmitting = false;
            } else {
              console.log(result);
              this.$noty.success('Your cashier has been updated!');
              this.$router.push({ name: 'Cashier' });
            }
          })
          .catch((error) => {
            this.errors = error.response.data;
            this.isSubmitting = false;
          });
      } else {
        this.isSubmitting = false;
        swal(
          'Error!',
          'Please Enter At Least Rp ' + this.formatPrice(this.totalPay),
          'error'
        );
      }
    },
  },
  computed: {
    totalMember() {
      return (
        parseInt(this.data.total_price) *
        (parseInt(this.total_point.cashback) / 100)
      );
    },
    totalDiscount() {
      if (this.form.discount_type == 'REBATE') {
        return parseInt(this.form.discount);
      } else if (this.form.discount_type == 'PERCENTAGE') {
        return parseInt(
          this.data.total_price * parseFloat(this.form.discount / 100)
        );
      } else if (this.form.discount_type == 'APPLICATION') {
        return (
          parseInt(this.data.total_price) *
            (parseInt(this.total_point.cashback) / 100) +
          parseInt(this.form_application.use_loyalty) +
          parseInt(this.form_application.use_point)
        );
      } else {
        return 0;
      }
    },
    totalMoney() {
      if (
        this.discount_type == 'REBATE' ||
        this.discount_type == 'APPLICATION'
      ) {
        return parseInt(this.form.total_payout) + parseInt(this.form.discount);
      } else if (this.form.discount_type == 'PERCENTAGE') {
        return parseInt(this.form.total_payout);
      } else {
        return parseInt(this.form.total_payout);
      }
    },
    totalPay() {
      return this.data.total_price - this.totalDiscount;
    },
    totalExchange() {
      return this.totalMoney - this.totalPay;
    },
  },
};
</script>
